@tailwind base;
@tailwind components;
@tailwind utilities;


/* Hide the scrollbar track */
::-webkit-scrollbar {
  /* display: none; */
  background-color: transparent;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(59 130 246 ); 
  border-radius: 10px; 
}

/* Optionally, style the track (the area where the thumb moves) */
::-webkit-scrollbar-track {
  background-color: black; 
}

/* Optionally, style the corner where two scrollbars meet */
::-webkit-scrollbar-corner {
  background-color: #73282b; /* color of the corner */
}
